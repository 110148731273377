$color-blue: #0250FC;
$color-blue-dark: #0139E6;
$color-blue-tint: #2b6cff;
$color-blue-gradient: linear-gradient(to bottom right, $color-blue-tint, $color-blue-dark);

$color-red: #f5222d;
$color-red-dark: #de202a;
$color-red-tint: #ff2933;
$color-red-gradient: linear-gradient(to bottom right, $color-red-tint, $color-red-dark);

$color-yellow: #F3C311;
$color-yellow-dark: #d5a705;
$color-yellow-tint: #fac828;
$color-blue-gradient: linear-gradient(to bottom right, $color-yellow-tint, $color-yellow-dark);

$color-text: #222;
$color-description: #888;
