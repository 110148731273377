@import "var.scss";

* {
  font-family: "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
}

input[type=text], input[type=password], textarea {
  -webkit-appearance: none;
}

/* UI PAGE */
.ui-page {
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
}


/* UI BUTTON */
.ui-button {
  display: inline-block;
  background: $color-yellow;
  color: #232323;
  padding: 8px 18px 9px 18px;
  border-radius: 50px;
  font-size: 15px;
  cursor: pointer;
  user-select: none;
  margin-left: 5px;
  text-align: center;
}

.ui-button.small {
  padding: 3px 10px 4px 10px;
}

.ui-button.outline {
  background: transparent;
  border: 1px solid $color-yellow;
  color: $color-yellow;
}

.ui-button.red {
  background: $color-red;
  background: $color-red-gradient;
}

.ui-button.red.outline {
  background: transparent;
  border: 1px solid $color-red;
  color: $color-red;
}

.ui-button.blue {
  background: $color-blue;
  background: $color-blue-gradient;
}

.ui-button.blue.outline {
  background: transparent;
  border: 1px solid $color-blue;
  color: $color-blue;
}

.ui-button:first-child {
  margin-left: 0;
}

.ui-button:active {
  opacity: 0.8;
}

.ui-button i {
  position: relative;
  top: -1px;
  font-size: 12px;
  margin-right: 5px;
}

.ui-button.lg-icon i {
  font-size: 18px;
  top: 1px;
  margin-right: 8px;
}

.ui-button.full {
  display: block;
  margin-left: 0;
  padding: 9px 15px 10px 15px;
}

.ui-button.block {
  display: block;
  border-radius: 5px;
  margin-left: 0;
  padding: 9px 15px 10px 15px;
}




/* UI TAG */
.ui-tag {
  display: inline-block;
  font-size: 13px;
  padding: 5px 10px 6px 10px;
  border-radius: 50px;
  user-select: none;
  border: 1px solid #ddd;
  margin-left: 5px;
  color: #444;
}

.ui-tag i {
  position: relative;
  top: -1px;
  font-size: 14px;
  margin-right: 5px;
}

.ui-tag:first-child {
  margin-left: 0;
}

.ui-tag.outline {
  border: none;
  padding: 5px 5px 6px 5px;
}


/* FLOAT */
.ui-fr {
  float: right;
}

.ui-fl {
  float: left;
}

/* TEXT ALIGN */
.ui-tr {
  text-align: right;
}

.ui-tl {
  text-align: left;
}

.ui-cb {
  clear: both;
}


/* COLOR */
.ui-text {
  color: $color-text;
  font-size: 15px;
}

.ui-description {
  color: $color-description;
  font-size: 12px;
}


/* UI TABS */
.ui-tabs > * {
  position: relative;
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
  background: #F7F7F9;
  color: #232323;
  border-radius: 5px;
  padding: 5px 10px;
}

.ui-tabs.outline > * {
  background: transparent;
  padding: 2px 10px;
}

.ui-tabs > *:last-child {
  margin-right: 0;
}

.ui-tabs > .active {
  background: #232323;
  color: white;
}

.ui-tabs.outline > .active {
  background: transparent;
  color: #232323;
}

.ui-tabs.outline > .active:before {
  content: '';
  position: absolute;
  left: 0; top: 0; bottom: 0;
  height: 100%;
  width: 2px;
  background: $color-yellow;
}


/* 悬浮图标 */
ion-fab.ui-fab {
  --ion-color-primary: transparent;
  --ion-color-primary-shade: transparent;
  margin-right: calc(20px + var(--ion-safe-area-right));
  margin-bottom: var(--ion-safe-area-bottom);

  ion-fab-button {
    --background: transparent;
    --background-hover: transparent;
    --box-shadow: none;
    --border-radius: none;
    width: 45px;
    height: 45px;
  }

  ion-fab-button > div {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: white;
    border: 1px solid #ddd;
    border-left-width: 0.68px;
    border-top-width: 0.68px;
    border-right-width: 0.55px;
    border-bottom-width: 0.55px;
    font-size: 12px;
    color: #888;

    & > div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    & > img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: none;
      width: 18px;
      height: 18px;
    }

    & > .big {
      width: 24px;
      height: 24px;
    }
  }
}
